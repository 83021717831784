import React from "react"
import { graphql } from "gatsby"

type OwnProps = {
  data: {
    markdownRemark: {
      frontmatter: {
        date: string
        title: string
      }
      html: string
    }
  }
}

const Guides = ({ data }: OwnProps) => {
  const source = { __html: data.markdownRemark.html }
  return (
    <div className="quick-guide">
      <div dangerouslySetInnerHTML={source}></div>
    </div>
  )
}

export const query = graphql`
  query GetMarkDownFromFile($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`

export default Guides
